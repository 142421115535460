.select {
  width: 100%;
}

.wrapper {
  overflow: auto;
  display: grid;
  grid-template-rows: repeat(10, 30px);
  gap: 10px;
  grid-auto-flow: column;
  grid-auto-columns: 500px;
}

.rows {
  padding: 5px 0;
  display: flex;
}

.rowLabel {
  width: 50px;
  padding-right: 10px;
}

.rowField {
  width: 500px;
}